<template>
  <div>
    <div class="mb-4">
      <a-table
        :columns="columns"
        :dataSource="items"
        :pagination="true"
        :class="$style.table"
        rowKey="id"
        :loading="isLoading"
      >
        <template slot="fullname" slot-scope="fullname, record">
          <div>
            <div v-if="isAdmin">
              <router-link
                :to="{ path: '/verifikator/details/' + record.id }"
                class="kit__utils__link font-size-16"
                >{{ record.fullname }}</router-link
              >
            </div>
            <div v-else>{{ record.fullname }}</div>
            <!-- <div class="text-gray-4">{{record.tag? record.tag: '-'}}</div> -->
          </div>
        </template>
        <template slot="createdAt" slot-scope="createdAt">
          <div>
            <div>
              {{ createdAt ? moment(createdAt).format('DD MMM YYYY') : '-' }}
            </div>
          </div>
        </template>
        <template slot="email" slot-scope="email">
          <div>
            <div>{{ email ? email : '-' }}</div>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import { getAll } from '@/services/axios/api/verifikator'
import moment from 'moment'
const columns = [
  {
    title: 'Nama Verifikator',
    dataIndex: 'fullname',
    className: 'bg-transparent text-gray-6 width-50',
    scopedSlots: { customRender: 'fullname' },
    sorter: (a, b) => {
      return a.fullname.localeCompare(b.fullname)
    },
  },
  // {
  //   title: 'Jenis Kelamin',
  //   dataIndex: 'gender',
  //   className: 'bg-transparent text-gray-6',
  //   scopedSlots: { customRender: 'gender' },
  //   sorter: (a, b) => { return a.gender.localeCompare(b.gender) },
  // },
  {
    title: 'Email',
    dataIndex: 'email',
    className: 'bg-transparent text-gray-6 width-50',
    scopedSlots: { customRender: 'email' },
    sorter: (a, b) => {
      return a.email.localeCompare(b.email)
    },
  },
  {
    title: 'Tanggal Bergabung',
    dataIndex: 'createdAt',
    className: 'bg-transparent text-gray-6 width-50',
    scopedSlots: { customRender: 'createdAt' },
    sorter: (a, b) => {
      return a.createdAt.localeCompare(b.createdAt)
    },
  },
]

export default {
  name: 'TabelVerifikator',
  data: function () {
    return {
      columns,
      items: [],
      isLoading: true,
      isAdmin: false,
    }
  },
  created() {
    this.getListData()
    this.getRole()
  },
  methods: {
    moment,
    getRole() {
      const role = window.localStorage.getItem('role')
      // console.log(role)
      if (role === 'admin') {
        this.isAdmin = true
      }
    },
    async getListData() {
      const res = await getAll()
      this.isLoading = false
      this.items = res
    },
  },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
